<template>
  <div>
    <div class="row mx-0 pt-3">
      <div class="col-md-12 pl-0 row mx-0">
        <div class="col-8 py-4 px-4 border_b_silver bg-white">
          <span class="card-label font-weight-bolder Main_Blue"
            >Jobs listing</span
          >
        </div>
        <div
          class="col-4 p-0 pt-3 text-end border_b_silver bg-white"
          style="position: relative"
        >
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            label="Search"
            class="mt-0 pt-0 pr-4"
            single-line
            hide-details
          ></v-text-field>
          <img
            src="\images/nav_icon/serch1.svg"
            alt=""
            height="16px"
            width="16px"
            class="img_search_icon mr-4"
          />
        </div>
        <v-card>
          <!-- <v-card-title>
            <v-btn
              class="btn btn-sm btn-success font-weight-bold py-2 px-3 px-xxl-5 my-1"
              fab
              small
              @click="createFormEnable"
            >
              <v-icon dark>mdi-plus</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-text-field
              style="width: 0%"
              v-model="search"
              append-icon="search"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title> -->
          <v-data-table
            :headers="headers"
            :items="data"
            :search="search"
            item-key="name"
            class="elevation-1 pt-4"
          >
            <template v-slot:item="props">
              <tr class="border__ch">
                <td
                  class="Main_Blue"
                  style="font-size: 10px; font-weight: 500 !important"
                >
                  {{ props.item.job_title }}
                </td>
                <td
                  class="Main_Blue p-0"
                  style="
                    font-size: 10px;
                    font-weight: 500 !important;
                    max-width: 115px !important;
                  "
                >
                  {{ props.item.location_city.toString() }}
                </td>
                <td
                  class="Main_Blue"
                  style="font-size: 10px; font-weight: 500 !important"
                >
                  {{ props.item.salary_min }}-{{ props.item.salary_max }}
                </td>
                <td
                  class="Main_Blue p-0"
                  style="font-size: 10px; font-weight: 500 !important"
                >
                  {{ props.item.job_type.toString() }}
                </td>
                <td
                  class="Main_Blue"
                  style="font-size: 10px; font-weight: 500 !important"
                >
                  {{ props.item.job_function }}
                </td>
                <td
                  class="Main_Blue"
                  style="font-size: 10px; font-weight: 500 !important"
                >
                  {{ props.item.experience.toString() }}
                </td>
                <td
                  class="Main_Blue"
                  style="font-size: 10px; font-weight: 500 !important"
                >
                  {{ props.item.education }}
                </td>
                <td
                  class="Main_Blue"
                  style="font-size: 10px; font-weight: 500 !important"
                >
                  {{ props.item.gender.toString() }}
                </td>
                <td
                  class="Main_Blue"
                  style="font-size: 10px; font-weight: 500 !important"
                >
                  {{ props.item.no_of_vacancies }}
                </td>
                <td
                  class="Main_Blue"
                  style="font-size: 10px; font-weight: 500 !important"
                >
                  {{ props.item.apply_by }}
                </td>
                <td class="pt-2" style="display: flex; flex-direction: row">
                  <v-btn
                    class="py-2 ch_btn_silver_rounded_0"
                    fab
                    small
                    :disabled="props.item.block"
                    @click="changeFeature(props.item)"
                    v-if="props.item.feature == 0"
                  >
                    <img
                      src="\images/job_application/Featured_icon_normal.svg"
                      alt=""
                      width="16"
                      height="16"
                    />
                  </v-btn>
                  <v-btn
                    class="py-2 ch_btn_silver_rounded_02"
                    v-if="props.item.feature == 1"
                    :disabled="props.item.block"
                    @click="changeFeature(props.item)"
                    fab
                    small
                  >
                    <img
                      src="\images/job_application/Featured_Icon.svg"
                      alt=""
                      width="16"
                      height="16"
                    />
                  </v-btn>
                  <v-btn
                    class="py-2 ch_btn_silver_rounded_0"
                    fab
                    small
                    :disabled="props.item.block"
                    @click="changeSpotlight(props.item)"
                    v-if="props.item.spotlight == 0"
                  >
                    <img
                      src="\images/job_application/spotlight_icon_normal.svg"
                      alt=""
                      width="16"
                      height="16"
                    />
                  </v-btn>
                  <v-btn
                    class="py-2 ch_btn_silver_rounded_02"
                    fab
                    small
                    :disabled="props.item.block"
                    @click="changeSpotlight(props.item)"
                    v-if="props.item.spotlight == 1"
                  >
                    <img
                      src="\images/job_application/Spotlight_enabled_icon.svg"
                      alt=""
                      width="16"
                      height="16"
                    />
                  </v-btn>
                  <v-btn
                    class="py-2 ch_btn_silver_rounded_0"
                    fab
                    small
                    :disabled="props.item.block"
                    @click="changeUrgent(props.item)"
                    v-if="props.item.urgent == 0"
                  >
                    <img
                      src="\images/job_application/Urgent_icon.svg"
                      alt=""
                      width="16"
                      height="16"
                    />
                  </v-btn>
                  <v-btn
                    class="py-2 ch_btn_silver_rounded_02"
                    fab
                    small
                    :disabled="props.item.block"
                    @click="changeUrgent(props.item)"
                    v-if="props.item.urgent == 1"
                  >
                    <img
                      src="\images/job_application/Urgent_active_icon.svg"
                      alt=""
                      width="16"
                      height="16"
                    />
                  </v-btn>
                  <v-btn
                    class="py-2 ch_btn_silver_rounded_0"
                    fab
                    small
                    :disabled="props.item.block"
                    @click="changeStatus(props.item)"
                    v-if="props.item.status == 0"
                  >
                    <img
                      src="\images/job_application/pending_icon.svg"
                      alt=""
                      width="16"
                      height="16"
                    />
                  </v-btn>
                  <v-btn
                    class="py-2 ch_btn_silver_rounded_02"
                    fab
                    small
                    :disabled="props.item.block"
                    @click="changeStatus(props.item)"
                    v-if="props.item.status == 1"
                  >
                    <img
                      src="\images/job_application/Approved_icon.svg"
                      alt=""
                      width="16"
                      height="16"
                    />
                  </v-btn>
                  <v-btn
                    class="py-2 ch_btn_silver_rounded_0"
                    fab
                    small
                    @click="changeBlockStatus(props.item)"
                    v-if="props.item.block == 0"
                  >
                    <img
                      src="\images/job_application/Block_icon_normal.svg"
                      alt=""
                      width="16"
                      height="16"
                    />
                  </v-btn>
                  <v-btn
                    class="py-2 ch_btn_silver_rounded_03"
                    fab
                    small
                    @click="changeBlockStatus(props.item)"
                    v-if="props.item.block == 1"
                  >
                    <img
                      src="\images/job_application/blocked_icon.svg"
                      alt=""
                      width="16"
                      height="16"
                    />
                  </v-btn>
                  <v-btn
                    class="py-2 ch_btn_silver_rounded_0"
                    fab
                    small
                    @click="deleteButtonClick(props.item)"
                  >
                    <img
                      src="\images/job_application/Delete.svg"
                      alt=""
                      width="16"
                      height="16"
                    />
                  </v-btn>
                </td>
                <!-- <td>
                  <toggle-button
                    :value="props.item.feature == '' + 1 ? true : false"
                    color="#82C7EB"
                    :sync="true"
                    :labels="false"
                    @change="changeFeature(props.item)"
                  />
                  {{ props.item.feature ? "Featured" : "-" }}
                </td>
                <td>
                  <toggle-button
                    :value="props.item.spotlight == '' + 1 ? true : false"
                    color="#82C7EB"
                    :sync="true"
                    :labels="false"
                    @change="changeSpotlight(props.item)"
                  />
                  {{ props.item.feature ? "Spotlight" : "-" }}
                </td>
                <td>
                  <toggle-button
                    :value="props.item.urgent == '' + 1 ? true : false"
                    color="#82C7EB"
                    :sync="true"
                    :labels="false"
                    @change="changeUrgent(props.item)"
                  />
                  {{ props.item.feature ? "Spotlight" : "-" }}
                </td>
                <td>
                  <toggle-button
                    :value="props.item.status == '' + 1 ? true : false"
                    color="#82C7EB"
                    :sync="true"
                    :labels="false"
                    @change="changeStatus(props.item)"
                  />
                  {{ props.item.status ? "Approved" : "Pending" }}
                </td>
                <td>
                  <toggle-button
                    :value="props.item.block == '' + 1 ? true : false"
                    color="#82C7EB"
                    :sync="true"
                    :labels="false"
                    @change="changeBlockStatus(props.item)"
                  />
                  {{ props.item.block ? "Deactivated" : "Activated" }}
                </td>
                <td style="display: flex; flex-direction: row">
                  <v-btn
                    class="
                      btn btn-sm btn-success
                      font-weight-bold
                      py-2
                      px-3 px-xxl-5
                      my-1
                    "
                    fab
                    small
                    @click="editButtonClick(props.item.id)"
                  >
                    <v-icon dark>mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn
                    class="
                      btn btn-sm btn-danger
                      font-weight-bold
                      py-2
                      px-3 px-xxl-5
                      my-1
                    "
                    fab
                    small
                    @click="deleteButtonClick(props.item)"
                  >
                    <v-icon dark>mdi-delete</v-icon>
                  </v-btn>
                </td> -->
              </tr>
              <tr>
                <td colspan="12" style="height: 12px"></td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </div>
      <div class="col-md-4">
        <!-- create form start -->
        <v-form ref="form" v-if="createForm" v-model="valid" lazy-validation>
          <v-text-field
            v-model="city"
            :rules="nameRules"
            label="City name"
            required
          ></v-text-field>
          <b-form-select
            v-model="state_id"
            :options="statesOptions"
            size="sm"
            class="mt-3"
          ></b-form-select>
          <div style="margin-top: 5%">
            <v-btn
              class="
                btn btn-sm btn-success
                font-weight-bold
                py-2
                px-3 px-xxl-5
                my-1
              "
              @click="createCity"
            >
              Save
            </v-btn>
            <v-btn
              style="margin-left: 6%"
              class="
                btn btn-sm btn-danger
                font-weight-bold
                py-2
                px-3 px-xxl-5
                my-1
              "
              @click="cancel"
            >
              Cancel
            </v-btn>
          </div>
        </v-form>
        <!-- create form end -->

        <!-- update form start -->
        <v-form ref="form" v-if="updateForm" v-model="valid" lazy-validation>
          <v-text-field
            v-model="city"
            :rules="nameRules"
            label="City name"
            required
          ></v-text-field>
          <b-form-select
            v-model="state_id"
            :options="statesOptions"
            size="sm"
            class="mt-3"
          ></b-form-select>
          <div style="margin-top: 5%">
            <v-btn
              class="
                btn btn-sm btn-success
                font-weight-bold
                py-2
                px-3 px-xxl-5
                my-1
              "
              @click="updateCity"
            >
              Update
            </v-btn>
            <v-btn
              style="margin-left: 6%"
              class="
                btn btn-sm btn-danger
                font-weight-bold
                py-2
                px-3 px-xxl-5
                my-1
              "
              @click="cancel"
            >
              Cancel
            </v-btn>
          </div>
        </v-form>
        <!-- update form end -->
      </div>
    </div>
    <!-- <div class="row mx-0 m-0 pt-3">
      <div class="col-4 py-4 px-4 border_b_silver bg-white">
        <span class="columnheader1">ACTIONS DEFAULT: </span>
      </div>
      <div class="col-4 py-4 px-4 border_b_silver bg-white">
        <span class="columnheader1">ACTIONS ACTIVE:</span>
      </div>
      <div class="col-4 py-4 px-4 border_b_silver bg-white">
        <span class="columnheader1">ACTIONS WHEN JOB BLOCKED:</span>
      </div>
    </div> -->
  </div>
</template>
<script>
import axios from "axios";
import { API_URL } from "@/core/api";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      search: "",
      loading: true,
      foo: false,
      success: false,
      token: `Bearer ${localStorage.getItem("token")}`,
      valid: true,
      createForm: false,
      updateForm: false,
      city: "",
      city_id: null,
      state_id: null,
      nameRules: [
        (v) => !!v || "Name is required",
        (v) => (v && v.length <= 10) || "Name must be less than 10 characters",
      ],
      headers: [
        { text: "Job Title", value: "job_title" },
        { text: "City", value: "location_city" },
        { text: "Salary Range", value: "salary_min" },
        // { text: "Salary Max.", value: "salary_max" },
        { text: "Job Type", value: "job_type" },
        { text: "Job Function", value: "job_function" },
        { text: "Experience", value: "experience" },
        { text: "Education", value: "education" },
        { text: "Gender", value: "gender" },
        { text: "No. of Vacancies", value: "no_of_vacancies" },
        { text: "Expiry date", value: "apply_by" },
        // { text: "Feature", value: "feature" },
        // { text: "Spotlight", value: "spotlight" },
        // { text: "Urgent", value: "urgent" },
        // { text: "Status", value: "status" },
        // { text: "Block", value: "block" },
        { text: "Action", value: "action" },
      ],
      data: [],
    };
  },
  mounted() {
    this.fetch();
    this.fetchStates();
  },
  methods: {
    cancel() {
      this.createForm = false;
      this.updateForm = false;
      this.city = "";
      this.state_id = null;
    },
    createFormEnable() {
      this.$router.push({ name: "job-create" });
    },
    editButtonClick(id) {
      this.data.forEach((element) => {
        if (element.id == id) {
          localStorage.setItem("job_edit_obj", JSON.stringify(element));
          this.$router.push({ name: "job-edit" });
        }
      });
    },
    fetch() {
      axios.defaults.headers.common["Authorization"] = this.token;
      axios
        .get(`${API_URL}/manage-jobs`)
        .then((res) => {
          this.data = res.data;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    fetchStates() {
      axios.defaults.headers.common["Authorization"] = this.token;
      axios
        .get(`${API_URL}/states`)
        .then((res) => {
          res.data.forEach((element) => {
            this.statesOptions.push({
              value: element.id,
              text: element.state_name,
            });
          });
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    createCity() {
      if ((this.city == "", this.state_id == null)) {
        Swal.fire({
          title: "",
          text: "Please, provide correct data!",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false,
        });
        return;
      }
      axios.defaults.headers.common["Authorization"] = this.token;
      axios
        .post(`${API_URL}/cities`, {
          state_id: this.state_id,
          city_name: this.city,
        })
        .then((res) => {
          this.loading = false;
          this.success = true;
          this.city = "";
          Swal.fire({
            title: "",
            text: "City added",
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          this.fetch();
        })
        .catch(() => {
          this.loading = false;
          Swal.fire({
            title: "",
            text: "Something went wrong!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        });
    },
    updateCity() {
      if ((this.city == "", this.state_id == null)) {
        Swal.fire({
          title: "",
          text: "Please, provide correct data!",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false,
        });
        return;
      }
      axios.defaults.headers.common["Authorization"] = this.token;
      axios
        .put(`${API_URL}/cities/${this.city_id}`, {
          state_id: this.state_id,
          city_name: this.city,
        })
        .then((res) => {
          this.loading = false;
          this.success = true;
          (this.city = ""), (this.state_id = null);
          Swal.fire({
            title: "",
            text: "City updated",
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          this.fetch();
          this.cancel();
        })
        .catch(() => {
          this.loading = false;
          Swal.fire({
            title: "",
            text: "Something went wrong!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        });
    },

    deleteButtonClick(item) {
      axios.defaults.headers.common["Authorization"] = this.token;
      axios
        .delete(`${API_URL}/cities/${item.id}`)
        .then((res) => {
          Swal.fire({
            title: "",
            text: "City deleted",
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          this.fetch();
          this.loading = false;
        })
        .catch(() => {
          Swal.fire({
            title: "",
            text: "Something went wrong!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          this.loading = false;
        });
    },

    changeBlockStatus(item) {
      axios.defaults.headers.common["Authorization"] = this.token;
      axios
        .get(`${API_URL}/job-block/${item.id}`)
        .then((res) => {
          this.foo = true;
          Swal.fire({
            title: "",
            text: "Job has been updated",
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          this.fetch();
        })
        .catch(() => {
          this.loading = false;
          Swal.fire({
            title: "",
            text: "Something went wrong!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        });
    },
    changeStatus(item) {
      axios.defaults.headers.common["Authorization"] = this.token;
      axios
        .get(`${API_URL}/job-status/${item.id}`)
        .then((res) => {
          Swal.fire({
            title: "",
            text: "Job has been updated",
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          this.fetch();
        })
        .catch(() => {
          this.loading = false;
          Swal.fire({
            title: "",
            text: "Something went wrong!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        });
    },
    changeFeature(item) {
      axios.defaults.headers.common["Authorization"] = this.token;
      axios
        .get(`${API_URL}/job-feature/${item.id}`)
        .then((res) => {
          Swal.fire({
            title: "",
            text: res.data,
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          this.fetch();
        })
        .catch(() => {
          this.loading = false;
          Swal.fire({
            title: "",
            text: "Something went wrong!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        });
    },
    changeSpotlight(item) {
      axios.defaults.headers.common["Authorization"] = this.token;
      axios
        .get(`${API_URL}/job-spotlight/${item.id}`)
        .then((res) => {
          Swal.fire({
            title: "",
            text: res.data,
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          this.fetch();
        })
        .catch(() => {
          this.loading = false;
          Swal.fire({
            title: "",
            text: "Something went wrong!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        });
    },
    changeUrgent(item) {
      axios.defaults.headers.common["Authorization"] = this.token;
      axios
        .get(`${API_URL}/job-urgent/${item.id}`)
        .then((res) => {
          Swal.fire({
            title: "",
            text: res.data,
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          this.fetch();
        })
        .catch(() => {
          this.loading = false;
          Swal.fire({
            title: "",
            text: "Something went wrong!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        });
    },
  },
};
</script>